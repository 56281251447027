import React from 'react'

import { Document } from '@debbie/common/dist/documents'
import File from '@debbie/iris/dist/components/File/File'

const Documentation = (props: { document: Document }) => {
  const { document } = props

  const file = document.signedFile || document.file

  if (!file) return null

  return <File preview file={file} />
}

export default Documentation

import React from 'react'
import styled from 'styled-components'

import { Col, Row } from '@debbie/iris/dist/components/Grid'

type Props = {
  collector: any
}

const Footer = (props: Props) => {
  const { collector } = props

  return (
    <Row>
      <Col width="12">
        <Content>
          {collector && (
            <p>
              <a href={`${collector.website}`}>{collector.website}</a> &bull;{' '}
              <a href={collector.privacyPolicy}>Privatlivspolitik</a>{' '}
              {collector.address && (
                <>
                  &bull; {collector.address.address},{' '}
                  {collector.address.zipcode} {collector.address.city}
                </>
              )}
            </p>
          )}
          {collector && <p style={{ fontSize: '0.9em' }}>{collector.name}</p>}
        </Content>
      </Col>
    </Row>
  )
}

export default Footer

const Content = styled.div`
  padding: 30px 0 0 0;

  a {
    color: initial;
  }
`

export const NAV_LEFT_WIDTH = 250
export const NAV_TOP_HEIGHT = 60
export const SUB_MENU_HEIGHT = 50

export const COLOR_PRIMARY = '#200D54'
export const COLOR_SECONDARY = '#f4f4f4'
export const DANGEROUS = '#dc3545'
export const WARNING = '#ffc107'
export const SUCCESS = '#28a745'
export const DARK_COLOR_SECONDARY = '#A2A2A2'
export const SELECTED_BLUE = '#08C'

export const MEDIA_QUERY_MOBILE = 'screen and (max-width: 770px)'

export const GRID_SPACING = 20
export const DATE_FORMAT_SHORT = 'DD.MM.YYYY'
export const DATE_FORMAT = 'Do MMMM YYYY'
export const DATE_FORMAT_LONG = 'Do MMMM YYYY, H:mm:ss'

import q from '@debbie/cortex/dist/query'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BankAccountType } from '@debbie/common/dist'
import { getCollectorOnCase } from '@debbie/common/dist/cases'
import helpers from '@debbie/common/dist/economy/helpers'
import { c, h } from '@debbie/cortex/dist'
import * as customersAPI from '@debbie/cortex/dist/api/customers'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import keys from '@debbie/common/dist/cache/keys'
import {
  pendingCustomerOverpaymentsKey,
  usePendingCustomerOverpayments,
} from '@debbie/cortex/dist/customers'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  Button,
  PageBase,
  Content,
  Panel,
  Money,
  PlaceholderLoader,
} from '@debbie/iris/dist/components'
import BankAccount from '@debbie/iris/dist/components/economy/BankAccount'
import { Container } from '@debbie/iris/dist/components/Grid'
import ThumbsUp from '@debbie/iris/dist/components/Icons/ThumbsUp'
import THEME, { updateTheme } from '@debbie/iris/dist/theme'

import { APIBASE } from '../../config'

import { AccountInput } from './CaseStyles'
import { useCaseVouchersByCase } from '@debbie/cortex/dist/case-vouchers'

const Overpayment = () => {
  const { id } = useParams<{ id: string }>()

  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const lang = useSelector((s: RootState) => s.lang)
  const caseVouchers_ = useCaseVouchersByCase(singleCase?.caseId)
  const caseVouchers = h.hasData(caseVouchers_) ? caseVouchers_.data : null
  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )

  const pendingOverpayments = usePendingCustomerOverpayments(
    customer?.customerId,
  )
  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  const [editingBankAccount, setEditingBankAccount] = useState<boolean>(false)
  const [accountNumber, setAccountNumber] = useState<[string, string]>(['', ''])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    loadData(id)
  }, [id])

  useEffect(() => {
    if (singleCase) {
      c.creditors.get(singleCase.creditorId)
      c.cases.getSatisfactions(singleCase.caseId)
    }
  }, [singleCase])

  const [themeUpdated, setThemeUpdated] = useState<boolean>(false)
  useEffect(() => {
    if (collector) {
      updateTheme(collector.theme)
      setThemeUpdated(true)
    }
  }, [collector])

  useEffect(() => {
    if (singleCase && creditor)
      c.collectors.getById(
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      )
  }, [creditor, singleCase])

  useEffect(() => {
    if (singleCase && !customer) {
      c.customers.getById(singleCase.customerId, true)
    }
  }, [singleCase])

  const loadData = (id) => {
    if (!singleCase) {
      c.cases.getById(id)
    }
  }

  const onSubmit = (e, verify: boolean = false) => {
    e.stopPropagation()
    e.preventDefault()

    const changed =
      customer.bankAccount?.accountNumber !== accountNumber[1] ||
      customer.bankAccount?.registrationNumber !== accountNumber[0]

    customersAPI
      .updateBankAccount(
        singleCase.customerId,
        verify
          ? undefined
          : changed
          ? {
              type: BankAccountType.Danish,
              registrationNumber: accountNumber[0],
              accountNumber: accountNumber[1],
            }
          : undefined,
      )
      .then(() => {
        setEditingBankAccount(false)
        c.customers
          .getById(singleCase.customerId)
          .then(() => setAccountNumber(['', '']))

        q.invalidate([pendingCustomerOverpaymentsKey(singleCase.customerId)])
      })
  }

  const overpayment = caseVouchers && helpers.overview.balance(caseVouchers) > 0

  const bankAccountExists = customer?.bankAccount != null

  const bankAccountNeedsVerification =
    h.hasData(pendingOverpayments) && pendingOverpayments.data.length > 0

  return (
    <div>
      <Content fullHeight>
        <PageBase>
          <Container style={{ maxWidth: '500px', textAlign: 'center' }}>
            <Panel>
              {!(
                collector &&
                singleCase &&
                creditor &&
                customer &&
                caseVouchers
              ) ? (
                <>
                  <PlaceholderLoader width="70%" />
                  <PlaceholderLoader width="50%" />
                  <PlaceholderLoader width="55%" />
                </>
              ) : (
                <>
                  <div style={{ margin: '2em 0em 1em 0em' }}>
                    {collector &&
                      (collector.logo ? (
                        <img
                          style={{ maxHeight: '70px' }}
                          alt={collector.logo.fileName}
                          src={`${APIBASE}/files/${collector.logo.fileId}/${collector.logo.fileName}`}
                        />
                      ) : (
                        <h3>{collector.name}</h3>
                      ))}
                  </div>

                  {overpayment && (
                    <>
                      <h1>{lang.debtorWeb.overpaymentHeader}</h1>
                      <p>
                        {lang.debtorWeb.overpaymentPreAmount}{' '}
                        {singleCase.currency}{' '}
                        <Money
                          amount={helpers.overview.balance(caseVouchers)}
                        />{' '}
                        {lang.debtorWeb.overpaymentPostAmount}
                      </p>
                      {bankAccountExists && !editingBankAccount ? (
                        <>
                          <BankAccount
                            editable={true}
                            onEdit={() => setEditingBankAccount(true)}
                            accountDetails={customer.bankAccount}
                          />
                          {themeUpdated && bankAccountNeedsVerification && (
                            <>
                              <br />
                              <Button
                                onClick={(e) => {
                                  onSubmit(e, true)
                                }}
                              >
                                {lang.misc.submit}
                              </Button>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <form
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            onSubmit={onSubmit}
                          >
                            <AccountInput
                              onChange={(accountNumber) =>
                                setAccountNumber(accountNumber)
                              }
                              value={accountNumber}
                            />
                            <Button
                              style={{
                                marginBottom: '20px',
                                padding: '13px',
                              }}
                            >
                              {lang.misc.submit}
                            </Button>
                          </form>
                          {editingBankAccount && (
                            <Button
                              theme="secondary"
                              size="small"
                              onClick={() => setEditingBankAccount(false)}
                            >
                              {lang.misc.cancel}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Panel>
          </Container>
        </PageBase>
      </Content>
    </div>
  )
}

export default Overpayment

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { validatePhone } from '@debbie/common/dist'
import { c, h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Panel, PlaceholderLoader } from '@debbie/iris/dist/components'
import { Container } from '@debbie/iris/dist/components/Grid'
import Globe from '@debbie/iris/dist/components/Icons/Globe'
import Mail from '@debbie/iris/dist/components/Icons/Mail'
import Phone from '@debbie/iris/dist/components/Icons/Phone'

import useCollector from '../hooks/useCollector'
import Criipto from '../pages/Criipto'

type Props = {
  hide: boolean
  logo?: string
}

const Welcome = (props: Props) => {
  const authToken = useSelector((s: RootState) => s.authToken)
  const { isLoading, collector } = useCollector()
  const { hide, logo } = props

  const _logo = (collector && collector.logoSrc) || logo

  return (
    <WelcomeWrapper className={hide ? 'hide fadein' : 'fadein'}>
      <Container style={{ maxWidth: '400px', textAlign: 'center' }}>
        <Panel>
          {collector ? (
            <div>
              {_logo ? (
                <img
                  src={_logo}
                  className="logo"
                  alt="logo"
                  height="30px"
                  style={{
                    margin: '30px 0 60px',
                  }}
                />
              ) : (
                <h2
                  style={{
                    margin: '30px 0 60px',
                  }}
                >
                  {collector && collector.name}
                </h2>
              )}
              {h.isError(authToken) ? (
                <div className="warning">{authToken.error.message}</div>
              ) : null}
              {collector && <Criipto collector={collector} />}
            </div>
          ) : (
            isLoading && <PlaceholderLoader />
          )}
        </Panel>
        {collector && collector.centralRegisterId && (
          <p>
            <Globe style={{ margin: '0.15em 0 -0.15em' }} /> CVR:{' '}
            {collector.centralRegisterId}
          </p>
        )}
        {collector && collector.email && (
          <p>
            <Mail style={{ margin: '0.15em 0 -0.15em' }} /> Email:{' '}
            {collector.email}
          </p>
        )}
        {collector && validatePhone(collector.phone) && (
          <p>
            <Phone style={{ margin: '0.15em 0 -0.15em' }} /> Tlf.: +
            {collector.phone.locale} {collector.phone.number}
          </p>
        )}
      </Container>
    </WelcomeWrapper>
  )
}

export default Welcome

const WelcomeWrapper = styled.div`
  position: fixed;
  z-index: 99999999;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: left;
  transition: transform 0.4s;
  padding: 50px 0;

  &.hide {
    transform: translateY(-140vh);
  }
`

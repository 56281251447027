import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Loading } from '@debbie/iris/dist/components'
import { Container, Col, Row } from '@debbie/iris/dist/components/Grid'
import THEME from '@debbie/iris/dist/theme'

import welcomeBg from '../assets/images/background.jpg'
import useCollector from '../hooks/useCollector'

import Footer from './Footer'

type Props = {
  hide?: boolean
  logo?: string
}

const Signed = (props: Props) => {
  const isAuthTokenLoading = useSelector((s: RootState) =>
    h.isLoading(s.authToken),
  )
  const { collector } = useCollector()
  const { hide, logo } = props

  return (
    <SignedWrapper
      className={hide ? 'hide fadein' : 'fadein'}
      style={{
        background: `url(${welcomeBg}) no-repeat center center`,
        WebkitBackgroundSize: 'cover',
        MozBackgroundSize: 'cover',
        OBackgroundSize: 'cover',
        backgroundSize: 'cover',
      }}
    >
      <Container>
        <Main
          style={{
            backgroundColor:
              collector?.theme?.COLOR_PRIMARY || THEME.COLOR_PRIMARY,
          }}
        >
          <Row>
            <Col width="8">
              <Content>
                <h1>Dokumentet er underskrevet</h1>
              </Content>
            </Col>
            {collector && (
              <Col width="4">
                <SignedContent>
                  <div
                    style={{
                      opacity: isAuthTokenLoading ? 1 : 0,
                      transform: isAuthTokenLoading
                        ? 'translateY(0px)'
                        : 'translateY(50px)',
                    }}
                    className="loading"
                  >
                    <Loading style={{ width: '100%' }} />
                  </div>
                  <div
                    style={{
                      opacity: isAuthTokenLoading ? 0 : 1,
                      transform: isAuthTokenLoading
                        ? 'translateY(-50px)'
                        : 'translateY(0px)',
                    }}
                  >
                    <img
                      src={(collector && collector.logoSrc) || logo}
                      className="logo"
                      alt="logo"
                      width="100%"
                    />
                  </div>
                </SignedContent>
              </Col>
            )}
          </Row>
        </Main>
        <Row>
          <Col width="12">{collector && <Footer collector={collector} />}</Col>
        </Row>
      </Container>
    </SignedWrapper>
  )
}

export default Signed

const SignedWrapper = styled.div`
  position: fixed;
  z-index: 99999999;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  transition: transform 0.4s;
  padding: 50px 0;

  &.hide {
    transform: translateY(-140vh);
  }
`

const Content = styled.div`
  text-align: left;
  color: #f4f4f4;
  padding: 20px 40px;

  h1 {
    font-size: 3em;
    font-style: italic;
    font-weight: 900;
  }

  p {
    font-size: 1.2em;
  }

  & > div > p:last-child {
    font-size: 0.9em;
    font-weight: 900;
    color: lightgrey;

    svg {
      margin: 2px 10px -2px;

      &:first-child {
        margin: 2px 10px -2 0;
      }
    }
  }
`

const Main = styled.div`
  padding: 20px 40px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px;
  border-radius: 3px;

  @media (max-width: 991px) {
  }
`

const SignedContent = styled.div`
  position: relative;
  background-color: #f4f4f4;
  padding: 70px 50px;
  margin: -20px -45px -20px 20px;

  @media (max-width: 991px) {
    margin: 0px -35px -20px -35px;
  }

  .loading {
    position: absolute;
    top: 0;
    text-align: center;
    width: 100%;
    padding: 60px;
    /*z-index: -1;*/
  }

  .logo {
    height: 90px;
    max-width: 80%;
    margin-bottom: 45px;
  }

  & > div {
    transition: opacity 0.4s, transform 0.4s;
  }

  .warning {
    border-radius: 3px;
    background-color: #ff5252;
    padding: 12px 15px;
    font-size: 0.8em;
    margin-bottom: 20px;
    width: 100%;
    background-image: none;
    border: 1px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: white;
  }
`

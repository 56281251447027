import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'

import { Currency, formatNumber } from '@debbie/common/dist'
import * as _collectors from '@debbie/common/dist/collectors'
import { Plan } from '@debbie/common/dist/economy/payment-plans'
import { RootState } from '@debbie/cortex/dist/reducer'
import Table from '@debbie/iris/dist/components/Table'

import { DATE_FORMAT_SHORT } from '../theme'

type Props = {
  paymentPlan: Plan
  currency: Currency
}

const EntriesList = (props: Props) => {
  const { paymentPlan, currency } = props
  const lang = useSelector((s: RootState) => s.lang)

  const entries = paymentPlan.plan

  return (
    <div>
      <p>{lang.debtorWebPaymentPlans.paymentPlanEntries}</p>
      <Table
        zebra
        columns={[
          { title: lang.debtorWebPaymentPlans.amount },
          { title: lang.debtorWebPaymentPlans.date },
          { title: lang.debtorWebPaymentPlans.status },
        ]}
      >
        {entries
          ? entries.map((entry, index) => {
              return (
                <tr key={index}>
                  <td>
                    {currency} {formatNumber(entry.amount)}
                  </td>
                  <td>{moment(entry.date).format(DATE_FORMAT_SHORT)}</td>
                  <td>{lang.debtorWebPaymentPlans.statusCode[entry.status]}</td>
                </tr>
              )
            })
          : []}
      </Table>
    </div>
  )
}

export default EntriesList

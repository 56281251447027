import React, { useEffect, useState } from 'react'

import * as collectorsAPI from '@debbie/cortex/dist/api/collectors'
import { updateTheme, BASIC_THEME } from '@debbie/iris/dist/theme'

import { IS_PROD } from '../config'

const useCollector = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [collector, setCollector] = useState<any>(null)

  useEffect(() => {
    fetchCollector()
  }, [])

  const fetchCollector = async () => {
    if (!isLoading && !collector) {
      setIsLoading(true)

      const domainBase = window.location.hostname
      const newCollector = await collectorsAPI.getBySiteBase(
        !IS_PROD ? 'demo.com' : domainBase,
      )

      updateTheme(newCollector.theme || BASIC_THEME)

      if (newCollector) {
        setIsLoading(false)
        setCollector(newCollector)

        document.title = newCollector.name
      } else {
        setIsLoading(false)
      }
    }
  }

  return { isLoading, collector }
}

export default useCollector

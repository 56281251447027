import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { CaseListItem } from '@debbie/common/dist/cases'
import { Button } from '@debbie/iris/dist/components'

interface Props {
  case: CaseListItem
}

const CaseSummary = (props: Props) => {
  const history = useHistory()
  return (
    <TableRow key={props.case.id}>
      <td>{props.case.id}</td>
      <td>
        <Button
          onClick={() => {
            const path = `/cases/${props.case.id}`
            history.push(path)
          }}
          className="ui-test-260b3416"
        >
          Se mere
        </Button>
      </td>
    </TableRow>
  )
}

export default CaseSummary

const TableRow = styled.tr`
  height: 55px;

  & > td > button {
    display: inline-block;
    color: #fff;
  }
`

import React from 'react'

import { Case } from '@debbie/common/dist/cases'
import { CaseVoucher } from '@debbie/common/dist/economy/case-vouchers'
import { Plan } from '@debbie/common/dist/economy/payment-plans'
import { UserRole } from '@debbie/common/dist/users'

import CongratulationsStep from './CongratulationsStep'
import ConnectCardStep from './ConnectCardStep'
import SelectPlanStep from './SelectPlanStep'
import SigningStep from './SigningStep'

export enum Steps {
  SELECTPLAN = 'SELECTPLAN',
  SIGNING = 'SIGNING',
  CONNECTCARD = 'CONNECTCARD',
  CONGRATULATIONS = 'CONGRATULATIONS',
}

interface Props {
  singleCase: Case
  paymentPlan: Plan
  caseVouchers: CaseVoucher[]
  meRole: UserRole
  step: Steps
}

const Step = ({
  singleCase,
  caseVouchers,
  meRole,
  step,
  paymentPlan,
}: Props) => {
  switch (step) {
    case Steps.SELECTPLAN:
      return (
        <SelectPlanStep
          caseId={singleCase.caseId}
          caseVouchers={caseVouchers}
          currency={singleCase.currency}
        />
      )
    case Steps.CONNECTCARD:
      return (
        <ConnectCardStep paymentPlan={paymentPlan} singleCase={singleCase} />
      )
    case Steps.SIGNING:
      return (
        <SigningStep
          meRole={meRole}
          paymentPlan={paymentPlan}
          singleCase={singleCase}
        />
      )
    case Steps.CONGRATULATIONS:
      return (
        <CongratulationsStep
          currency={singleCase.currency}
          caseId={singleCase.caseId}
          paymentPlan={paymentPlan}
        />
      )
  }
}

export default Step

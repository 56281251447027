import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { CaseListItem } from '@debbie/common/dist/cases'
import { c } from '@debbie/cortex/dist'
import { selectCaseListData } from '@debbie/cortex/dist/cases/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  Content,
  Panel,
  Table,
  Loading,
} from '@debbie/iris/dist/components'
import { Col, Row } from '@debbie/iris/dist/components/Grid'

import { CaseSummary } from '.'

const Cases = () => {
  const history = useHistory()

  const [loadingCases, setLoadingCases] = useState<boolean>(true)
  const [cases, setCases] = useState<CaseListItem[]>([])
  const _cases = useSelector((s: RootState) => selectCaseListData(s))
  const [attempts, setAttempts] = useState(0)

  useEffect(() => {
    if (!_cases && attempts < 5) {
      c.cases.getMultiple({}, 10)
      setAttempts(attempts + 1)
    }
  }, [_cases])

  useEffect(() => {
    console.log(!cases.length && _cases, attempts)
    if (!cases.length && _cases) {
      setCases(_cases)
      setLoadingCases(false)
    }
  }, [_cases])

  useEffect(() => {
    if (cases && cases.length === 1) {
      const path = `/cases/${cases[0].id}`
      history.push(path)
    }
  }, [cases])

  return (
    <div>
      <Content>
        <PageBase>
          <Row>
            <Col size="md" width="12">
              <Panel header="Alle sager">
                {!loadingCases ? (
                  cases.length > 0 ? (
                    <Table>
                      <tr>
                        <th>ID</th>
                        <th style={{ width: '120px' }} />
                      </tr>
                      {cases.map((c) => (
                        <CaseSummary case={c} key={c.id} />
                      ))}
                    </Table>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <span
                        style={{
                          fontStyle: 'italic',
                          opacity: '0.8',
                          fontSize: '1em',
                        }}
                      >
                        No cases found
                      </span>
                    </div>
                  )
                ) : (
                  <Loading width="30px" />
                )}
              </Panel>
            </Col>
          </Row>
        </PageBase>
      </Content>
    </div>
  )
}

export default Cases

import { Location } from 'history'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom'

import { TenantStatus } from '@debbie/common/dist/tenants'
import { c, h } from '@debbie/cortex/dist'
import {
  selectMe,
  selectTenant as selectMeTenant,
} from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenantId } from '@debbie/cortex/dist/selectedOwners/selectors'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'

import { Cases, Case, Satisfaction, CreatePaymentPlan } from './pages/cases'
import Overpayment from './pages/cases/Overpayment'

const LoggedInView = () => {
  const location: Location = useLocation()
  const selectedTenants = useSelector((s: RootState) => s.selectedOwners)
  const lang = useSelector((s: RootState) => s.lang)
  const isMeNotLoaded = useSelector((s: RootState) => h.isNotLoaded(s.me))
  const me = useSelector(selectMe)
  const meTenant = useSelector(selectMeTenant)
  const tenant = useSelector(selectTenant)
  const tenantId = useSelector(selectTenantId)

  useEffect(() => {
    isMeNotLoaded &&
      c.me.get().then(() => {
        if (me) {
          // Set selected owner if none is selected
          if (me.tenants[0] && selectedTenants.length !== 1) {
            c.owners.select(me.tenants[0].id)
          }
        }
      })
  }, [])

  useEffect(() => {
    if (meTenant) {
      const status =
        meTenant.status !== TenantStatus.Active
          ? `${lang.tenantStatus[meTenant.status]}: `
          : ''
      document.title = `${status}${meTenant.name} - Debbie`
    }
  }, [meTenant])

  useEffect(() => {
    if (tenantId) {
      c.tenants.get(tenantId)
    }
  }, [tenantId])

  if (!tenant) return null

  return (
    <Switch location={location}>
      <Route exact path="/">
        <Cases />
      </Route>
      <Route exact path="/cases">
        <Cases />
      </Route>
      <Route path="/cases/:id" exact>
        <Case />
      </Route>
      <Route path="/cases/:id/paid" exact>
        <Case />
      </Route>
      <Route path="/cases/:id/payment-plan" exact>
        <CreatePaymentPlan />
      </Route>
      <Route path="/cases/:id/satisfaction" exact>
        <Satisfaction />
      </Route>
      <Route path="/cases/:id/overpayment" exact>
        <Overpayment />
      </Route>
      <Route path="/cases/:id/satisfaction/:preSelectedSatisfaction" exact>
        <Satisfaction />
      </Route>
    </Switch>
  )
}

export default LoggedInView

import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatNumber, validatePhone } from '@debbie/common/dist'
import { Case, getCollectorOnCase } from '@debbie/common/dist/cases'
import {
  PaymentOption,
  PaymentOptionType,
} from '@debbie/common/dist/collectors'
import * as _collectors from '@debbie/common/dist/collectors'
import { CaseVoucher } from '@debbie/common/dist/economy/case-vouchers'
import helpers from '@debbie/common/dist/economy/helpers'
import * as _paymentPlans from '@debbie/common/dist/economy/payment-plans'
import { User } from '@debbie/common/dist/users'
import { h } from '@debbie/cortex/dist'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { usePaymentPlansByCaseId } from '@debbie/cortex/dist/paymentPlans'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  Button,
  File,
  Checkbox,
  LineThrough,
} from '@debbie/iris/dist/components'
import ArrowRight from '@debbie/iris/dist/components/Icons/ArrowRight'
import Mail from '@debbie/iris/dist/components/Icons/Mail'
import Phone from '@debbie/iris/dist/components/Icons/Phone'

import card_dan from '../assets/images/card_dan.png'
import card_master from '../assets/images/card_master.png'
import card_visa from '../assets/images/card_visa.png'
import mobilepay from '../assets/images/mobilepay.png'
import payment_bs from '../assets/images/payment_bs.png'
import payment_ls from '../assets/images/payment_ls.png'
import { APIBASE } from '../config'
import { GRID_SPACING, DATE_FORMAT_SHORT } from '../theme'

const Info = (props: {
  caseVouchers: CaseVoucher[]
  singleCase: Case
  me: User
}) => {
  const { caseVouchers, singleCase, me } = props

  const [businesstermsAccepted, setBusinesstermsAccepted] =
    useState<boolean>(false)

  const authToken = useSelector((s: RootState) => s.authToken)

  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )

  const paymentPlansLoadable = usePaymentPlansByCaseId(singleCase?.caseId)
  const paymentPlans = h.hasData(paymentPlansLoadable)
    ? paymentPlansLoadable.data
    : null

  const collector = useSelector(
    (s: RootState) =>
      singleCase &&
      creditor &&
      selectCollectorById(
        s,
        getCollectorOnCase(
          singleCase.type,
          singleCase.collectorId,
          creditor.identityCollectorId,
          creditor.defaultCollectorId,
        ),
      ),
  )

  const lang = useSelector((s: RootState) => s.lang)

  const paymentPlan = _paymentPlans.getCurrentPaymentPlan(paymentPlans)

  const paymentOptions: PaymentOption[] =
    collector &&
    collector.paymentOptions &&
    singleCase &&
    singleCase.currency &&
    _collectors.getPaymentOptions(collector.paymentOptions, singleCase.currency)

  const reepay =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) => paymentOption.type === PaymentOptionType.REEPAY,
    )

  const farpay =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) => paymentOption.type === PaymentOptionType.FARPAY,
    )

  const mobilepeople =
    paymentOptions &&
    paymentOptions.find(
      (paymentOption) => paymentOption.type === PaymentOptionType.MOBILE_PEOPLE,
    )

  return (
    <>
      <LineThrough>
        <span>{lang.debtorWebPaymentPlans.info}</span>
      </LineThrough>
      {paymentPlan && (
        <InfoArea>
          <h2>{lang.debtorWebPaymentPlans.paymentPlan}</h2>
          <p>
            {moment(paymentPlan.plan[0]?.date).format(DATE_FORMAT_SHORT)}{' '}
            <ArrowRight />{' '}
            {moment(paymentPlan.plan[paymentPlan.plan.length - 1]?.date).format(
              DATE_FORMAT_SHORT,
            )}
          </p>
          <p>
            {paymentPlan.plan.length} {lang.debtorWebPaymentPlans.months}
          </p>
          <p>
            {formatNumber(_paymentPlans.getAmount.entry(paymentPlan.plan))}{' '}
            {singleCase && singleCase.currency}/
            {lang.debtorWebPaymentPlans.entry}
          </p>
          {_paymentPlans.getAmount.upfront(paymentPlan.plan) && (
            <p>
              {lang.debtorWebPaymentPlans.firstPayment}:{' '}
              {singleCase && singleCase.currency}{' '}
              {formatNumber(_paymentPlans.getAmount.upfront(paymentPlan.plan))}
            </p>
          )}
          {_paymentPlans.getAmount.rest(paymentPlan.plan) && (
            <p>
              {lang.debtorWebPaymentPlans.lastPayment}:{' '}
              {singleCase && singleCase.currency}{' '}
              {formatNumber(_paymentPlans.getAmount.rest(paymentPlan.plan))}
            </p>
          )}
        </InfoArea>
      )}
      {paymentPlan && paymentPlan.documentFileId && (
        <InfoArea>
          <h2>{lang.debtorWebPaymentPlans.signedDoc}</h2>
          <p>
            <File
              file={{
                fileId: paymentPlan.documentFileId,
                contentType: 'application/pdf',
                fileName: 'skylderklæring.pdf',
              }}
            />
          </p>
        </InfoArea>
      )}
      {collector &&
        (validatePhone(collector.phone) || collector.email != null) && (
          <InfoArea>
            <h2>{lang.debtorWebPaymentPlans.needHelp}</h2>
            {collector && (
              <div>
                {collector.email != null && (
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={collector.email}
                  >
                    <Mail />{' '}
                    <a
                      style={{
                        color: 'inherit',
                      }}
                      href={`mailto:${collector.email}`}
                    >
                      {collector.email}
                    </a>
                  </p>
                )}
                {validatePhone(collector.phone) && (
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={`${collector.phone.locale} ${collector.phone.number}`}
                  >
                    <Phone />{' '}
                    <a
                      style={{
                        color: 'inherit',
                      }}
                      href={`tel:${collector.phone.locale}${collector.phone.number}`}
                    >
                      +{collector.phone.locale} {collector.phone.number}
                    </a>
                  </p>
                )}
              </div>
            )}
          </InfoArea>
        )}
      {singleCase &&
        ((reepay && reepay.isActive) || (farpay && farpay.isActive)) && (
          <InfoArea>
            <h2>{lang.debtorWebPaymentPlans.orPay}</h2>
            <p style={{ fontSize: '.6em' }}>
              <Checkbox
                value={businesstermsAccepted}
                onChange={(e) => setBusinesstermsAccepted(e.target.checked)}
                placeholder={
                  <span>
                    {lang.debtorWebHome.iApprove}{' '}
                    <a
                      target="_blank"
                      href={collector.businessTerms}
                      rel="noreferrer"
                    >
                      {lang.debtorWebHome.theBusinessTerms}
                    </a>
                  </span>
                }
              />
            </p>
            {me && h.isLoaded(authToken) && (
              <Button
                block
                style={{ fontSize: '.7em' }}
                size="small"
                theme="secondary"
                href={`${APIBASE}/payments/payment-portal/${
                  singleCase.caseId
                }/${collector.collectorId}?acceptURL=${
                  window.location.origin + `/${singleCase.id}/paid`
                }&customerName=${encodeURIComponent(
                  me.firstName + ' ' + me.lastName,
                )}&authToken=${authToken.data}`}
                disabled={
                  (caseVouchers &&
                    -helpers.overview.balance(caseVouchers) < 100) ||
                  !businesstermsAccepted
                }
              >
                {lang.debtorWebPaymentPlans.pay}
              </Button>
            )}
            <p
              style={{
                textAlign: 'center',
                marginTop: '15px',
              }}
            >
              {reepay &&
                reepay.isActive &&
                reepay.type === PaymentOptionType.REEPAY && (
                  <>
                    {reepay.details.visa && <Card src={card_visa} />}
                    {reepay.details.mastercard && <Card src={card_master} />}
                    {reepay.details.dankort && <Card src={card_dan} />}
                  </>
                )}
              {farpay &&
                farpay.isActive &&
                farpay.type === PaymentOptionType.FARPAY && (
                  <>
                    {farpay.details.visa && <Card src={card_visa} />}
                    {farpay.details.mastercard && <Card src={card_master} />}
                    {farpay.details.dankort && <Card src={card_dan} />}
                    {farpay.details.mobilepay && <Card src={mobilepay} />}
                    {farpay.details.betalingsservice && (
                      <Card src={payment_bs} />
                    )}
                    {farpay.details.leverandorservice && (
                      <Card src={payment_ls} />
                    )}
                  </>
                )}
              {mobilepeople &&
                mobilepeople.isActive &&
                mobilepay.type === PaymentOptionType.MOBILE_PEOPLE && (
                  <>
                    {mobilepeople.details.visa && <Card src={card_visa} />}
                    {mobilepeople.details.mastercard && (
                      <Card src={card_master} />
                    )}
                    {mobilepeople.details.dankort && <Card src={card_dan} />}
                    {mobilepeople.details.mobilepay && <Card src={mobilepay} />}
                    {mobilepeople.details.betalingsservice && (
                      <Card src={payment_bs} />
                    )}
                    {mobilepeople.details.leverandorservice && (
                      <Card src={payment_ls} />
                    )}
                  </>
                )}
            </p>
          </InfoArea>
        )}
    </>
  )
}

export default Info

const InfoArea = styled.div`
  font-size: 1.1em;
  margin-top: ${GRID_SPACING * 1.5}px;

  h2 {
    font-size: 0.8em;
    opacity: 0.8;
  }

  p {
    margin: 0.4em 0;
    font-size: 0.8em;
  }

  input[type='checkbox'] {
    font-size: 20px;
  }
`

const Card = styled.img`
  margin: 0 6px;
  height: 25px;
`

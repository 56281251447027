import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import styled from 'styled-components'

import { ID } from '@debbie/common/dist'
import { c } from '@debbie/cortex/dist'
import * as satisfactionsAPI from '@debbie/cortex/dist/api/satisfactions'
import { selectCaseSatisfactions } from '@debbie/cortex/dist/case/selectors'
import { selectCaseById } from '@debbie/cortex/dist/cases/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  Button,
  PageBase,
  Content,
  Panel,
  Textarea,
  PlaceholderLoader,
} from '@debbie/iris/dist/components'
import { Container } from '@debbie/iris/dist/components/Grid'
import ArrowLeft from '@debbie/iris/dist/components/Icons/ArrowLeft'
import Frown from '@debbie/iris/dist/components/Icons/Frown'
import Meh from '@debbie/iris/dist/components/Icons/Meh'
import Smile from '@debbie/iris/dist/components/Icons/Smile'
import ThumbsUp from '@debbie/iris/dist/components/Icons/ThumbsUp'

type SatisfactionNumber = 1 | 2 | 3 | 4 | 5

const getSatisfaction = (
  preSelectedSatisfaction,
): null | SatisfactionNumber => {
  if (!preSelectedSatisfaction) return null

  const parsedSatisfaction = parseInt(preSelectedSatisfaction)

  return parsedSatisfaction >= 0 && parsedSatisfaction <= 5
    ? (parsedSatisfaction as SatisfactionNumber)
    : null
}

const Satisfaction = () => {
  const params: { id: string; preSelectedSatisfaction: string } = useParams()
  const { id, preSelectedSatisfaction } = params

  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const satisfactions = useSelector(
    (s: RootState) =>
      singleCase && selectCaseSatisfactions(s, singleCase.caseId),
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [comment, setComment] = useState<null | string>(null)
  const [satisfaction, setSatisfaction] = useState<null | SatisfactionNumber>(
    getSatisfaction(preSelectedSatisfaction),
  )

  const sendSatisfaction = async (refId: ID) => {
    await satisfactionsAPI.add(refId, satisfaction, comment)
    await c.cases.getById(id)
    await c.cases.getSatisfactions(singleCase.caseId)
  }

  useEffect(() => {
    loadData(id)
  }, [id])

  useEffect(() => {
    if (singleCase) {
      c.cases.getSatisfactions(singleCase.caseId)
    }
  }, [singleCase])

  const loadData = (id) => {
    if (!singleCase) {
      c.cases.getById(id)
    }
  }

  return (
    <div>
      <Content fullHeight>
        <PageBase>
          <Container style={{ maxWidth: '500px', textAlign: 'center' }}>
            {singleCase && (
              // @ts-ignore
              <Link
                to={`/cases/${singleCase.id}`}
                style={{
                  textDecoration: 'none',
                }}
              >
                <p
                  style={{
                    color: 'grey',
                    fontSize: '.8em',
                    textAlign: 'left',
                  }}
                >
                  <b>
                    <ArrowLeft style={{ margin: '-.2em 0px' }} /> Tilbage til
                    min sag
                  </b>
                </p>
              </Link>
            )}
            <Panel>
              {satisfactions ? (
                satisfactions.length === 0 ? (
                  <>
                    <h1>Din mening betyder meget for os</h1>
                    <p>
                      For at kunne yde den bedst mulige service, vil vi meget
                      gerne høre om din oplevelse.
                    </p>
                    <h2>
                      Hvilken smiley beskriver bedst din oplevelse ved
                      behandlingen af sag #{singleCase && singleCase.id}
                    </h2>
                    <SmileySelect>
                      <SmileySelectItem
                        selected={satisfaction === 1}
                        onClick={() => setSatisfaction(1)}
                      >
                        <Frown />
                      </SmileySelectItem>
                      <SmileySelectItem
                        selected={satisfaction === 3}
                        onClick={() => setSatisfaction(3)}
                      >
                        <Meh />
                      </SmileySelectItem>
                      <SmileySelectItem
                        selected={satisfaction === 5}
                        onClick={() => setSatisfaction(5)}
                      >
                        <Smile />
                      </SmileySelectItem>
                    </SmileySelect>
                    <h2>Eventuel kommentar</h2>
                    <Textarea
                      placeholder="Eventuel kommentar"
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <Button
                      block
                      disabled={!satisfaction || !singleCase}
                      loading={isLoading}
                      onClick={async () => {
                        setIsLoading(true)
                        await sendSatisfaction(singleCase.caseId)
                        setIsLoading(false)
                      }}
                    >
                      Indsend
                    </Button>
                  </>
                ) : (
                  <>
                    <p
                      style={{
                        fontSize: '5em',
                      }}
                    >
                      <ThumbsUp />
                    </p>
                    <h1>Tak for din indsendelse af tilfredshed</h1>
                  </>
                )
              ) : (
                <PlaceholderLoader />
              )}
            </Panel>
          </Container>
        </PageBase>
      </Content>
    </div>
  )
}

export default Satisfaction

const SmileySelect = styled.div`
  font-size: 4em;
`

const SmileySelectItem = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  margin: 0 5px;
  padding: 15px 10px 0px 10px;
  border-radius: 4px;
  display: inline-block;

  background-color: ${(props) =>
    props.selected ? 'lightgrey' : 'transparent'};
`

import styled from 'styled-components'

import BankAccountInput from '@debbie/iris/dist/components/economy/BankAccountInput'
import THEME from '@debbie/iris/dist/theme'

export const AccountInput = styled(BankAccountInput)`
  justify-content: center;
  margin-right: 1em;
`

export const Language = styled.div<{ active: boolean }>`
  display: inline-block;
  margin-right: 5px;
  border-radius: 100%;
  height: 24px;
  width: 24px;

  svg {
    cursor: pointer;
    height: 20px;
    padding: 2px;
  }

  border: 2px solid
    ${(props) =>
      props.active ? `${THEME.COLOR_ACCENT}ff` : `${THEME.COLOR_ACCENT}00`};

  transition: border 0.2s;
`

export const Footer = styled.div`
  margin-top: 80px;
  margin-bottom: 40px;
  font-size: 0.9em;

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 700;

    li {
      display: inline-block;
      margin-right: ${THEME.GRID_SPACING}px;
      position: relative;

      a {
        text-decoration: underline;
        color: inherit;
        cursor: pointer;
      }

      &:after {
        content: '-';
        position: absolute;
        right: ${-THEME.GRID_SPACING / 2 - 3}px;
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  img {
    max-width: 150px;
    margin: 0 auto;
    display: block;
    margin-top: 30px;
  }
`

export const Info = styled.span`
  font-size: 0.9em;
`

export const Header = styled.h2`
  margin-top: 2em;
`

export const OnlyMobile = styled.div`
  display: none;

  @media only screen and (max-device-width: 600px) {
    display: block;
  }
`
export const OnlyNotMobile = styled.div`
  display: block;

  @media only screen and (max-device-width: 600px) {
    display: none;
  }
`

export const Intro = styled.div`
  h1 {
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }

  p {
    color: ${THEME.DARK_COLOR_SECONDARY};
  }
`

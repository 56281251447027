import React, { useEffect, useState } from 'react'

import { Address } from '@debbie/common/dist'
import { Collector } from '@debbie/common/dist/collectors'
import { c } from '@debbie/cortex/dist'
import * as criiptoLib from '@debbie/cortex/dist/api/criipto'
import { PlaceholderLoader, Table } from '@debbie/iris/dist/components'
import ArrowRight from '@debbie/iris/dist/components/Icons/ArrowRight'

import mitidLogoSrc from '../assets/images/mitid-logo.svg'
import { APIBASE } from '../config'

const Criipto = (props: { collector: Collector }) => {
  const { collector } = props

  const [loading, setLoading] = useState<boolean>(false)
  const [casesToLogin, setCasesToLogin] = useState<
    | null
    | {
        authToken: string
        caseSeqId: number
        caseId: string
        creditorName: string
        caseReferenceId: string
      }[]
  >(null)
  const [leveringsadresse, setLeveringsadresse] = useState<null | Address>(null)
  const [forbrugernummer, setForbrugernummer] = useState<null | string>(null)

  const code = window.location
    ? new URLSearchParams(window.location.search).get('code')
    : null

  const goToCase = (auth: { caseSeqId: number; authToken: string }) => {
    c.auth.setToken(auth.authToken)
    c.owners.select(collector.tenantId)
    location.replace(`/cases/${auth.caseSeqId}`)
  }

  useEffect(() => {
    const runAsync = async () => {
      if (collector && code) {
        setLoading(true)
        try {
          const auths = await criiptoLib.auth(
            collector.tenantId,
            code,
            window.location.origin,
          )

          if (auths.length === 1) {
            goToCase(auths[0])
          } else {
            setCasesToLogin(auths)
          }
        } catch (error) {}
        setLoading(false)
      }
    }
    runAsync()
  }, [code, collector])

  if (!collector.tenantId) return null

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: 'left' }}>
          <PlaceholderLoader />
          <PlaceholderLoader width="60%" />
          <PlaceholderLoader width="75%" />
        </div>
      ) : (
        collector &&
        (casesToLogin && Array.isArray(casesToLogin) ? (
          <div>
            {casesToLogin.length === 0 ? (
              <h3>
                Vi kunne ikke identificere dig. Kontakt venligst{' '}
                {collector.name}.
              </h3>
            ) : (
              <Table zebra style={{ textAlign: 'left' }}>
                {casesToLogin.map((_case) => (
                  <tr
                    key={_case.caseId}
                    onClick={() => {
                      goToCase(_case)
                    }}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <td>
                      <b>{_case.creditorName}</b>
                      <br />
                      {_case.caseReferenceId}
                    </td>
                    <td style={{ textAlign: 'right' }}>#{_case.caseSeqId}</td>
                  </tr>
                ))}
              </Table>
            )}
          </div>
        ) : (
          <div>
            <p>Log ind med MitID for at få adgang til dine data.</p>
            <a
              style={{ color: 'white' }}
              href={`${APIBASE}/v1/criipto?tenantId=${
                collector.tenantId
              }&message=${encodeURIComponent(
                `Se dine data vedr. ${collector.name}.`,
              )}&redirectURL=${encodeURIComponent(
                window.location.origin,
              )}&acrValues=${encodeURIComponent('urn:grn:authn:dk:mitid:low')}`}
            >
              <div
                style={{
                  backgroundColor: '#0060E6',
                  borderRadius: '3px',
                  padding: '14px 10px 12px 15px',
                  textAlign: 'left',
                  marginBottom: '10px',
                }}
              >
                <img height="20" src={mitidLogoSrc} />
                <p style={{ float: 'right', margin: '0' }}>
                  Log ind med MitID{' '}
                  <ArrowRight
                    style={{
                      margin: '.2em 0 -.2em',
                    }}
                  />
                </p>
              </div>
            </a>
          </div>
        ))
      )}
    </div>
  )
}

export default Criipto
